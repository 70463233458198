import React from 'react';
import { useHistory } from 'react-router-dom';
import SelectMessage from '../panels/SelectMessage';
import ForwardButton from '../buttons/ForwardButton';

interface Message {
  name: string,
  selects: Array<string>,
}

export default ((props) => {
  const history = useHistory();

  var messages: Array<Message> = [
    { name: '測定担当者がリモートで視力測定を行っております。\n待ち時間を確認いたしますので少々お待ちください。', selects: []},
    { name: 'リモート測定員からボタンを押すようにお願いいがございましたらこちらのボタンを押してください。', selects: []},
    { name: 'アルコールのアレルギーはございますか？\n消毒します。', selects: []},
    { name: '顎を乗せておでこをしっかりつけてください。\nお顔は動かさずに絵の中心を見てください。', selects: []},
    { name: '上から機械を下ろしたらお顔をくっつけて穴を覗いてください。', selects: []}
  ];

  return (
    <>
      <SelectMessage messages={messages}/>
      <ForwardButton message='視力測定開始' onClick={() => { history.push('/prescriptionInProgress')}}/>
    </>
  );
}) as React.FC;

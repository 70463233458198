import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Meaning from '../texts/Meaning';
import grey from '@material-ui/core/colors/grey';
import { ThemeProvider } from '@material-ui/styles';
import { LANGUAGES } from '../../i18n';

interface Props {
  name: string,
  buttonStyle?: any,
  shouldHideMeaning?: boolean,
  isSelected: boolean,
  isLong?: boolean,
  onClick: () => void,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      textAlign: 'center',
    },
    button: {
      width: '220px',
      height: '140px',
      fontSize: '0.8em',
      lineHeight: '1em',
      border: 'solid',
      borderWidth: '6px',
      borderColor: 'transparent',
      marginBottom: '10px',
      textTransform: 'none',
    },
    longButton: {
      fontSize: '2.5vmin',
      width: '250px',
      height: '120px',
    },
    selectedButton: {
      borderColor: 'blue',
    },
    meaning: {
      marginTop: '2px',
    },
  }),
);

export default ((props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { i18n } = useTranslation();

  var language = LANGUAGES.find(language => language.lang === i18n.language);
  var fontFamily = language ? language.font : '';
  
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: grey[900],
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.box}>
        <Button variant="contained" disableElevation disableRipple
          className={clsx(classes.button, (props.isSelected && props.isLong === undefined && classes.selectedButton ), props.isLong && classes.longButton)}
          style={props.buttonStyle}
          onClick={() => { props.onClick() }}
          color={props.isLong && props.isSelected ? "primary" : "inherit"}
        >
          <Box style={{fontFamily: fontFamily}}>
            {t(props.name)}
          </Box>
        </Button>
        {props.shouldHideMeaning || <Meaning>{props.name}</Meaning>}
      </Box>
    </ThemeProvider>
  );
}) as React.FC<Props>;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HomeButton from '../buttons/HomeButton';
import SelectCard from '../cards/SelectCard';
import BackButton from '../buttons/BackButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '50px 50px 0',
      height: '80%',
      fontSize: '2.8em',
    },
    form: {
      height: '20%',
      display: 'flex',

      justifyContent: 'space-evenly',
    },
  }),
);

export default (() => {
  const history = useHistory();
  const classes = useStyles();

  const selectButtons = [
    { name: '接客', purpose: 'service' },
    { name: '受付', purpose: 'reception' },
    { name: '視力測定', purpose: 'prescription' },
    { name: 'オプション', purpose: 'option' },
    { name: 'お会計', purpose: 'check'},
    { name: 'お受け取り', purpose: 'acceptance'}
  ];

  return (
    <>
      <HomeButton />
      <Grid container spacing={2} alignItems="center" className={classes.container}>
        <Grid item xs={12} style={{fontSize: '4vmin'}}>
          お客様にご案内する内容を選択してください
        </Grid>
        {selectButtons.map((button, index) =>
          <Grid item key={index} xs={12} sm={6} md={4}>
            <SelectCard
              fontSize="3vmin"
              isSelected={false}
              message={button.name}
              onClick={() => { history.push(`/${button.purpose}`)}}>
            </SelectCard>
          </Grid>
        )}
      </Grid>
      <BackButton onClick={() => { history.push('/')}}/>
    </>
  );
}) as React.FC;

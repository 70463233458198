import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SelectButton from '../buttons/SelectButton';
import Meaning from '../texts/Meaning';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
  }),
);

export default (() => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState<number | null>(null);

  const selectButtons = [
    { name: '強く' },
    { name: '弱く' },
  ];

  const handleChangeSelectedButton = (index: number) => {
    setSelectedButtonIndex(index === selectedButtonIndex ? null : index);
  };

  return (
    <>
      <Grid item xs={12}>
        <Meaning>※レンズを変えて比較します</Meaning>
        {t('※レンズを変えて比較します')}
      </Grid>
      <Grid item xs={8}>
        <Meaning>こちらでいかがですか？</Meaning>
        {t('こちらでいかがですか？')}
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'center', }}>
        <img src="/static/images/lens.jpg" style={{ width: '80%', height: 'auto' }} alt="lens" />
      </Grid>
      <Grid item xs={12} className={classes.form} >
        {selectButtons.map((button, index) =>
          <SelectButton key={index}
            name={button.name}
            isSelected={selectedButtonIndex === index}
            onClick={() => { handleChangeSelectedButton(index) }}>
          </SelectButton>
        )}
      </Grid>
    </>
  );
}) as React.FC;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SelectButton from '../buttons/SelectButton';
import Meaning from '../texts/Meaning';
import ReplayButton  from '../buttons/ReplayButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
  }),
);

export default (() => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState<number | null>(null);

  const selectButtons = [
    { name: '決定' },
    { name: 'やり直したい', style: { fontSize: i18n.language === 'ja' ? '0.55em' : '0.8em' } },
  ];

  const handleChangeSelectedButton = (index: number) => {
    setSelectedButtonIndex(index === selectedButtonIndex ? null : index);
  };

  return (
    <>
      <Grid item xs={12}>
        <Meaning>問題がなければ、こちらの度数でお作りいたします。</Meaning>
        {t('問題がなければ、こちらの度数でお作りいたします。')}
      </Grid>
      <Grid item xs={i18n.language === 'ja' ? 8 : 12}>
        <Meaning>よろしいですか？</Meaning>
        {t('よろしいですか？')}
      </Grid>
      {i18n.language === 'ja' && <Grid item xs={4} style={{ textAlign: 'center', }}>
        <img src="/static/images/glass.jpg" style={{ width: '80%', height: 'auto' }} alt="glass" />
      </Grid>
      }
      <Grid item xs={12} className={classes.form} style={{ marginTop: i18n.language === 'ja' ? 0 : '30px' }} >
        {selectButtons.map((button, index) =>
          <SelectButton key={index}
            name={button.name}
            buttonStyle={{ ...button.style }}
            isSelected={selectedButtonIndex === index}
            onClick={() => { handleChangeSelectedButton(index) }}>
          </SelectButton>
        )}
      </Grid>
      <ReplayButton onClick={() => { history.push('/purposeSelection') }}/>
    </>
  );
}) as React.FC;

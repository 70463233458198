import React from 'react';
import SelectMessage from '../panels/SelectMessage';

interface Message {
  name: string,
  selects: Array<string>,
}

export default ((props) => {

  var messages: Array<Message> = [
    {name: 'こちらが受付票です。\nQRコードをスキャンして受付をお願いします。\n※Languageボタンを押して選択可能', selects: []},
    {name: '普段使用しているメガネは、本日お持ちですか？', selects: []},
    {name: '現在のメガネと同じ度数や、処方箋でも作成できますが、視力測定はご希望ですか？', selects: ['A.同じ度数', 'B.処方箋作成', 'C.測定希望']},
    {name: 'コンタクトレンズはつけていますか？', selects: ['A.つけている(ソフト)', 'B.つけている(ハード)', 'C.つけていない']},
    {name: 'コンタクトを外してから使うメガネでよろしいですか？', selects: ['A.外してから使う。', 'B.コンタクトをしたままメガネを使う。']},
    {name: '海外発送は出来ません。\n日本国内の発送は可能です（¥550円）。\n※アウトレット店は一万円以上の買い物で送料無料です。\n他店受け取りも可能です（無料）。', selects: []},
  ]

  return (
    <>
      <SelectMessage messages={messages}/>
    </>
  );
}) as React.FC;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';

interface Props {
  fontSize: string,
  message: string,
  isSelected: boolean,
  onClick: () => void,
}

const useStyles = makeStyles({
  box: {
    height: '100%',
    textAlign: "center",
  },
  card: {
    height: '100%',
    maxHeight: '110%',
    border: 'solid',
    borderWidth: '2px',
  },
  selectedCard: {
    backgroundColor: 'black',
    borderColor: 'black',
    color: 'white',
  }
})

export default ((props) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Card variant="elevation" 
        onClick={() => { props.onClick() }}
        className={clsx(classes.card, props.isSelected && classes.selectedCard)}>
        <CardContent style={{ paddingBottom: "16px"}}>
            <Box fontSize={props.fontSize}>
              {props.message}
            </Box>
        </CardContent>
      </Card>
    </Box>
  );
}) as React.FC<Props>;


import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LanguageSelection from './components/pages/LanguageSelection';
import PurposeSelection from './components/pages/SelectPurpose';
import Service from './components/pages/Service';
import Reception from './components/pages/Reception';
import PrescriptionInProgress from './components/pages/PrescriptionInProgress';
import Prescription from './components/pages/Prescription';
import Option from './components/pages/Option';
import Check from './components/pages/Check';
import Acceptance from './components/pages/Acceptance';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    container: {
      height: '100%',
    },
  }),
);

export default function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/purposeSelection" render={() => <PurposeSelection /> }/>
            <Route exact path="/service" render={() => <Service />} />
            <Route exact path="/reception" render={() => <Reception />} />
            <Route exact path="/option" render={() => <Option />} />
            <Route exact path="/prescription" render={() => <Prescription />} />
            <Route exact path="/prescriptionInProgress" render={() => <PrescriptionInProgress />} />
            <Route exact path="/check" render={() => <Check />} />
            <Route exact path="/acceptance" render={() => <Acceptance />} />
            <Route render={() => <LanguageSelection />} />
          </Switch>
        </BrowserRouter>
      </Container>
    </div>
  );
}

import React from 'react';
import SelectMessage from '../panels/SelectMessage';

interface Message {
  name: string,
  selects: Array<string>,
}

export default ((props) => {

  var messages: Array<Message> = [
    { name: '【かけ心地】', selects: ['問題ない', '鼻に違和感がある', '耳が痛い', 'フレームがゆるい', 'フレームがきつい']},
    { name: '【見え方】', selects: ['問題ない', '強く感じる', '弱い', '見えにくい', '気持ち悪い']},
    { name: '海外のOWNDAYSでも保証のご案内ができます。\n国によって日本と違う可能性がありますのでご了承ください。\n（対応可能国）\n台湾、香港、カンボジア、タイ、オーストラリア、ベトナム、\nフィリピン、シンガポール、マレーシア、インドネシア、インド、ドバイ', selects: []},
    { name: '【保証説明】\nレンズとフレームに1年間保証がついています。\nレンズには1年に2回まで度数の交換ができる保証がついているので見え方に違和感を感じましたらご来店ください。\nメガネの調整やクリーニングは無料で承っておりますのでご安心ください。', selects: []},
    { name: '【保証説明】サングラス \n購入から6か月間初期不良の保証がついてます。\n返品、交換不可。\nサングラスの調整、クリーニングは無料で承ります。', selects: []},
    { name: '【保証説明（アウトレット）】\n レンズに1年間の保証が付いています。2回まで度数の交換ができる保証がついているので見え方に違和感を感じましたらご来店ください。\nアウトレット商品の為フレームの保証はありません。返品、交換不可。\n眼鏡の調整、クリーニングは無料で承ります。', selects: []},
  ]

  return (
    <>
      <SelectMessage messages={messages}/>
    </>
  );
}) as React.FC;

import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';

export default ((props) => {
  const history = useHistory();

  return (
    <IconButton style={{ position: 'fixed', top: 0, left: 0, fontSize: '32px' }} onClick={() => history.push('/')}>
      ホーム
    </IconButton>
  )
}) as React.FC;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationJA from './locales/ja/translation.json';
import translationEN from './locales/en/translation.json';
import translationZH from './locales/zhCN/translation.json';
import translationZHTW from './locales/zhTW/translation.json';
import translationKO from './locales/ko/translation.json';
import translationTH from './locales/th/translation.json';
import translationVI from './locales/vi/translation.json';


i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: translationJA,
    },
    en: {
      translation: translationEN,
    },
    zhCN: {
      translation: translationZH,
    },
    zhTW: {
      translation: translationZHTW,
    },
    ko: {
      translation: translationKO,
    },
    th: {
      translation: translationTH,
    },
    vi: {
      translation: translationVI,
    },
  },
  lng: 'ja',
  fallbackLng: 'ja',
  interpolation: { escapeValue: false },
});

export const LANGUAGES = [
  { name: '日本語', lang: 'ja', font: 'Noto Sans JP' },
  { name: '英語/English', lang: 'en', font: 'Noto Sans' },
  { name: '中国語(簡体字)/中国人', lang: 'zhCN', font: 'Noto Sans SC' },
  { name: '中国語(繁体字)/中國人', lang: 'zhTW', font: 'Noto Sans TC' },
  { name: '韓国語/한국어', lang: 'ko', font: 'Noto Sans KR'},
  { name: 'タイ語/ไทย', lang: 'th', font: 'Noto Sans Thai'},
  { name: 'ベトナム語/Tiếng Việt', lang: 'vi', font: 'Noto Sans SC'},
];
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SelectButton from '../buttons/SelectButton';
import Meaning from '../texts/Meaning';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
  }),
);

export default (() => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [selectedLineIndex, setSelectedLineIndex] = React.useState<number | null>(null);
  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState<number | null>(null);

  const selectLines = [
    { name: '1段目' },
    { name: '2段目' },
    { name: '3段目' },
  ];

  const selectButtons = [
    { name: 'はい' },
    { name: 'いいえ', style: { fontSize: i18n.language === 'zh' ? '0.4em' : '0.8em' } },
    { name: 'まあまあ', style: { fontSize: '0.6em' } },
  ];

  const handleChangeSelectedLine = (index: number) => {
    setSelectedLineIndex(index === selectedLineIndex ? null : index);
  };

  const handleChangeSelectedButton = (index: number) => {
    setSelectedButtonIndex(index === selectedButtonIndex ? null : index);
  };

  return (
    <>
      <Grid item xs={4} style={{ textAlign: 'center', }}>
        <img src="/static/images/select-character.jpg" style={{ width: '80%', height: 'auto' }} alt="select-character" />
      </Grid>
      <Grid item xs={8}>
        <Meaning>これらの文字がはっきり見えますか？</Meaning>
        {t('これらの文字がはっきり見えますか？')}
      </Grid>
      <Grid item xs={12} className={classes.form}  >
        {selectLines.map((button, index) =>
          <SelectButton key={index}
            name={button.name}
            buttonStyle={{ width: '160px' }}
            isSelected={selectedLineIndex === index}
            onClick={() => { handleChangeSelectedLine(index) }}>
          </SelectButton>
        )}
      </Grid>
      <Grid item xs={12} className={classes.form} >
        {selectButtons.map((button, index) =>
          <SelectButton key={index}
            name={button.name}
            buttonStyle={{ ...button.style, width: '160px' }}
            isSelected={selectedButtonIndex === index}
            onClick={() => { handleChangeSelectedButton(index) }}>
          </SelectButton>
        )}
      </Grid>
    </>
  );
}) as React.FC;

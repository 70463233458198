import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ReplayIcon from '@material-ui/icons/Replay';

interface Props {
  onClick: () => void,
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    button: {
      position: 'fixed',
      bottom: '10px',
    },
    icon: {
      fontSize: '3em',
    }
  }),
);

export default ((props) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.button} style={{ right: '10px' }} onClick={() => { props.onClick() }}>
      <ReplayIcon className={classes.icon} />
    </IconButton>
  )
}) as React.FC<Props>;
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import * as Panels from '../panels';
import HomeButton from '../buttons/HomeButton';
import BackButton from '../buttons/BackButton';
import ForwardButton from '../buttons/ForwardButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      padding: '70px 50px 0',
      height: '80%',
      fontSize: '2.8em',
    },
    container: {
      position: 'relative',
      height: '100%',
    },
  }),
);

export default ((props) => {
  const classes = useStyles();
  const history = useHistory();

  const [state, setState] = React.useState({
    operation: 'next',
    currentIndex: 0,
  });

  const panels = [
    <Panels.SelectFourDirection />, // 方向
    <Panels.SelectColor />, // 赤と緑
    <Panels.SelectPoint />, // 黒い点
    <Panels.SelectCrevice />, // 円の隙間
    <Panels.SelectCloseUp />, // 手元
    <Panels.SelectDirection />, // 右左
    <Panels.PleaseWalk />, // 立つ
    <Panels.PleaseLookFar />, // 遠く
    <Panels.PleaseLookNear />, // 近く
    <Panels.CompareLenses />, // レンズ比較
    <Panels.Confirm />, // 決定
  ];

  const getDirection = (index: number) => {
    if (state.currentIndex < index) {
      return 'left';
    } else if (state.currentIndex > index) {
      return 'right';
    } else if (state.operation === 'next') {
      return 'left';
    } else {
      return 'right';
    }
  };

  const handleChange = (newIndex: number) => {
    setState({
      operation: state.currentIndex > newIndex ? 'prev' : 'next',
      currentIndex: newIndex,
    });
  };

  return (
    <>
      <HomeButton />
      {panels.map((panel, index) =>
        <Slide key={index} direction={getDirection(index)} in={state.currentIndex === index} mountOnEnter unmountOnExit>
          <Box className={classes.panel}>
            <Grid container spacing={0} className={classes.container}>
              {panel}
            </Grid>
          </Box>
        </Slide>
      )}
      <BackButton onClick={() => state.currentIndex > 0 ? handleChange(state.currentIndex - 1) : history.push('/purposeSelection')}/>
      {state.currentIndex < (panels.length - 1) &&
        <ForwardButton onClick={() => handleChange(state.currentIndex + 1)} />
      }
    </>
  );
}) as React.FC;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Meaning from '../texts/Meaning';

export default (() => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <Meaning>円のすきまはどの方向にありますか？すきまを指し示してください。</Meaning>
        {t('円のすきまはどの方向にありますか？すきまを指し示してください。')}
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right', margin: 'auto' }}>
        <img src="/static/images/ling.png" style={{ width: '50%', height: 'auto' }} alt="ling"/>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'left', margin: 'auto' }}>
        <img src="/static/images/finger.png" style={{ width: '50%', height: 'auto' }} alt="finger" />
      </Grid>
    </>
  );
}) as React.FC;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SelectButton from '../buttons/SelectButton';
import Meaning from '../texts/Meaning';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      position: 'absolute',
      bottom: '10px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
    },
  }),
);

export default (() => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState<number | null>(null);

  const selectButtons = [
    { name: '問題なし' },
    { name: '見えづらい' },
    { name: 'きつい' },
  ];

  const handleChangeSelectedButton = (index: number) => {
    setSelectedButtonIndex(index === selectedButtonIndex ? null : index);
  };

  return (
    <>
      <Grid item xs={12} style={{paddingTop: '50px'}}>
        <Meaning>ご自身の手やこちらのペーパーを見てください。</Meaning>
        {t('ご自身の手やこちらのペーパーを見てください。')}
      </Grid>
      <Grid item xs={12} className={classes.form} >
        {selectButtons.map((button, index) =>
          <SelectButton key={index}
            name={button.name}
            buttonStyle={{ fontSize: '0.6em' }}
            isSelected={selectedButtonIndex === index}
            onClick={() => { handleChangeSelectedButton(index) }}>
          </SelectButton>
        )}
      </Grid>
    </>
  );
}) as React.FC;

import React from 'react';
import SelectMessage from '../panels/SelectMessage';

interface Message {
  name: string,
  selects: Array<string>,
  selectName?: string,
}

export default ((props) => {

  var messages: Array<Message> = [
    {selectName: '度なしPC', name: '度なしPCレンズは無料で変更できます。\nブルーライトを30％カットできます。\nレンズが少し黄色に見えて青光りが反射します。\n本日お渡しができます。', selects: []},
    {selectName: 'PC無色', name: 'クリアPCレンズは在庫があれば本日お渡しができます。\n追加4000円で変更可能です。\n表面が青く反射して少し黄色に見えます。', selects: []},
    {selectName: 'PCカラー', name: 'カラーPCレンズは7日後にお渡しができます。\n追加4000円で変更可能です。\n色と濃さによってカット率が上がります。', selects: []},
    {selectName: 'ニュアンスカラー', name: 'ニュアンスカラーレンズはブルーライトかっと効果もあり、\n目元がやさしく見えるので女性のお客様に人気です。\n追加4000円で変更可能で、7日後お渡しができます。', selects: []},
    {selectName: 'カラー', name: 'カラーレンズはサンプルから色と濃さを選べます。\nフルカラーとグラデーションカラーがあります。\nお渡しは7日後にできます。\n追加4000円で変更可能です。', selects: []},
    {selectName: 'UVPCダブルカット', name: 'UVPCダブルカットレンズはUV100％カットしてブルーライトを38％カットします。\n反射もスタンダードレンズと一緒ですが少しレンズに色がかかることがあります。\n追加6000円で変更可能です。\n在庫があれば本日お渡しできますが、なければ3日～1週間後にお渡しできます。', selects: []},
    {selectName: '曇り止め', name: '一緒にお渡ししてる専用メガネ拭きでメンテナンスが必要です。\n曇り止めレンズは7日後にお渡しできます。\n追加6000円で変更可能です。', selects: []},
    {selectName: '遠近両用', name: '遠近両用レンズは2種類あります。両方とも7日後にお渡しできます。\nメガネ1つで遠くも手元も見やすくなりますが、\n周辺部に歪みがあるのでなれるまでに時間が必要です。\nプレミアム遠近はスタンダードより手元が広く歪みが少ないです。\nスタンダードは追加6000円、プレミアムタイプは12000円で変更可能です。', selects: []},
    {selectName: '傷防止', name: '傷防止レンズはスタンダードより傷がつきにくく、ブルーライトカット機能もあります。\n追加6000円で変更可能です。\n1週間後にお渡しできます。', selects: []},
    {selectName: '調光', name: '調光レンズはメガネにもサングラスにも変わるレンズです。\n紫外線で色が変わるタイプとすべての光に反応するタイプがあります。\n紫外線で色が変わるタイプは6000円で変更可能です。\n6種類の色があって好きな色を選べることができ、3日後お渡しができます。\n遠近両用でも作れます。\n\nすべての光で色が変わるタイプは12000円で変更可能です。\nクリアではないですが、運転中にも色が変わるのでドライブに最適です。\n2種類の色があり、1週間後お渡しができます。\n※すべての光＝可視光線', selects: []},
    {selectName: '偏光', name: '偏光レンズは2つの色があります12000円で変更可能です。\n乱反射を抑えて海のギラギラやアスファルトの光反射を抑えます。\nお渡しは1週間後にできます。', selects: []},
    {selectName: '超極薄レンズ', name: '超極薄レンズは追加12000円で変更可能です。\nスタンダードよりさらに薄く仕上がります。\nお渡しは1週間後にできます。', selects: []},
    {selectName: 'サウナレンズ', name: 'サウナレンズは熱に強く、曇り止めの効果もあります。\n追加5500円で変更できます。\nお渡しは1週間後にできます。', selects: []}
  ];


  return (
    <>
      <SelectMessage messages={messages}/>
    </>
  );
}) as React.FC;
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SelectCard from '../cards/SelectCard';
import HomeButton from '../buttons/HomeButton';
import BackButton from '../buttons/BackButton';
import Meaning from '../texts/Meaning';
import LineBreak from '../texts/LineBreak';
import SelectButton from '../buttons/SelectButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '70px 70px 0',
      height: '80%',
      fontSize: '2.8em',
    },
    form: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    center: {
      verticalAlign: 'middle',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

interface Message {
  name: string,
  selectName?: string,
  selects: Array<string>,
}

interface Props {
  messages: Array<Message>,
}

export default ((props) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const [message, setMessage] = React.useState({
    name: '',
    selects: [''],
  });

  const settingMessage = (message: Message) => {
    setMessage({
      name: message.name,
      selects: message.selects,
    });
  };

  const resetMessage = () => {
    setMessage({
      name: '',
      selects: [''],
    });
    setSelectedButtonIndex(null);
  };

  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState<number | null>(null);
  const handleChangeSelectedButton = (index: number) => {
    setSelectedButtonIndex(index === selectedButtonIndex ? null : index);
  };

  return (
    <>
      <HomeButton />
      {message.name === '' &&
      <Grid container spacing={5} className={classes.container}>
        <Grid item xs={12} style={{ fontSize: '4vmin'}}>
          お客様にご案内する内容を選択してください
        </Grid>
        {props.messages.map((message, index) =>
          <Grid item key={index} xs={12} sm={6} md={4}>
            <SelectCard
              fontSize="2.2vmin"
              isSelected={false}
              message={message.selectName ? message.selectName : message.name}
              onClick={() => { settingMessage(message)}}/>
          </Grid>
        )}
        <BackButton onClick={() => {history.push('/purposeSelection')}}/>
      </Grid>
      }
      {message.name !== '' &&
        <Grid container spacing={1} className={classes.container}> 
          <Grid item key="0" xs={12} className={classes.center} >
            <Box alignItems="center" marginTop="100px" >
              <Meaning>{message.name}</Meaning>
              <LineBreak message={t(message.name)}/>
            </Box>
          </Grid>
          <>
          {message.selects.map((select, index) =>
            <Grid item key={index} xs={message.selects.length === 2 ? 6 : (message.selects.length === 3 ? 4 : 6) } className={classes.form}>
              <SelectButton
                name={select}
                isSelected={selectedButtonIndex === index}
                isLong={true}
                onClick={() => { handleChangeSelectedButton(index) }}>
              </SelectButton>
            </Grid>
          )}
          </>
        <BackButton onClick={() => {resetMessage()}} />
        </Grid>
      }
      </>
  );
}) as React.FC<Props>;

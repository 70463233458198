import React from 'react';
import SelectMessage from '../panels/SelectMessage';

interface Message {
  name: string,
  selects: Array<string>,
}

export default ((props) => {

    var messages: Array<Message> = [
      {name: 'レンズの在庫があります。\nお会計後下記の時間後にお渡しできます。', selects: ['20分', '30分', '40分', '50分', '1時間']},
      {name: '在庫を確認しましたがレンズが売り切れてるため、\n3日後のお渡しできますが 大丈夫ですか？', selects: []},
      {name: 'お会計終了後、引換券をお渡しします。', selects: []},
      {name: 'メガネケースはどれにしますか？', selects: []},
      {name: 'ショッパーは追加10円ですがご利用なさいますか？', selects: []},
      {name: 'こちらがメガネの引換券です。\n記載されてる時間以降にカウンターに引換券をお持ちください。\nメガネのお渡しの際、かけ具合の確認をさせていただきます。', selects: []}
  ];

  return (
    <>
      <SelectMessage messages={messages}/>
    </>
  );
}) as React.FC;

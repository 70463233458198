import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    meaning: {
      fontSize: '0.5em',
      margin: 0,
      lineHeight: '1em',
    },
  }),
);

export default ((props) => {
  const { i18n } = useTranslation();
  const classes = useStyles();

  return (
    <>{i18n.language !== 'ja' && <p className={classes.meaning}>{props.children}</p>}</>
  );
}) as React.FC;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SelectButton from '../buttons/SelectButton';
import Meaning from '../texts/Meaning';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      position: 'absolute',
      bottom: '10px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
    },
  }),
);

export default (() => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState<number | null>(null);

  const selectButtons = [
    { name: '右', style: { fontSize: i18n.language === 'zh' ? '0.65em' : '0.8em' } },
    { name: '左', style: { fontSize: i18n.language === 'zh' ? '0.65em' : '0.8em' } },
    { name: '同じ' },
  ];

  const handleChangeSelectedButton = (index: number) => {
    setSelectedButtonIndex(index === selectedButtonIndex ? null : index);
  };

  return (
    <>
      <Grid item xs={4} style={{ textAlign: 'center' }}>
        <img src="/static/images/left-right.jpg" style={{ width: '80%', height: 'auto' }} alt="left-right" />
      </Grid>
      <Grid item xs={8}>
        <Meaning>手で片目ずつ隠したとき右と左でしたらどちらがはっきり見えますか？</Meaning>
        {t('手で片目ずつ隠したとき右と左でしたらどちらがはっきり見えますか？')}
      </Grid>
      <Grid item xs={12} className={classes.form} >
        {selectButtons.map((button, index) =>
          <SelectButton key={index}
            name={button.name}
            buttonStyle={{ ...button.style }}
            isSelected={selectedButtonIndex === index}
            onClick={() => { handleChangeSelectedButton(index) }}>
          </SelectButton>
        )}
      </Grid>
    </>
  );
}) as React.FC;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SelectButton from '../buttons/SelectButton';
import Meaning from '../texts/Meaning';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      justifyContent: 'space-evenly',
    },
  }),
);

export default (() => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selectedButtonIndex, setSelectedButtonIndex] = React.useState<number | null>(null);

  const selectButtons = [
    { name: '赤', style: { backgroundColor: 'red', color: 'white' } },
    { name: '緑', style: { backgroundColor: 'green', color: 'white' } },
    { name: '同じ' },
  ];

  const handleChangeSelectedButton = (index: number) => {
    setSelectedButtonIndex(index === selectedButtonIndex ? null : index);
  };

  return (
    <>
      <Grid item xs={4} style={{ textAlign: 'center', }}>
        <img src="/static/images/select-color.jpg" style={{ width: '80%', height: 'auto' }} alt="select-color" />
      </Grid>
      <Grid item xs={8}>
        <Meaning>上の段の二重丸を見てください。</Meaning>
        {t('上の段の二重丸を見てください。')}
      </Grid>
      <Grid item xs={12}>
        <Meaning>赤と緑の二重丸でしたらどちらがはっきり見えますか？</Meaning>
        {t('赤と緑の二重丸でしたらどちらがはっきり見えますか？')}
      </Grid>
      <Grid item xs={12} className={classes.form}>
        {selectButtons.map((button, index) =>
          <SelectButton key={index}
            name={button.name}
            buttonStyle={{ ...button.style }}
            isSelected={selectedButtonIndex === index}
            onClick={() => { handleChangeSelectedButton(index) }}>
          </SelectButton>
        )}
      </Grid>
    </>
  );
}) as React.FC;

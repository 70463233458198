import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../i18n';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      fontSize: '4vmin',
    },
  }),
);

interface Props {
  message: string,
}

export default ((props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  var language = LANGUAGES.find(language => language.lang === i18n.language);
  var fontFamily = language ? language.font : '';

  const lbToBr = (txt: string) => {
    return (
      txt.split(/(\n)/g).map(t => (t === '\n')?<br/>:t)
    )
  }
  
  return (
    <div className={classes.text} style={{fontFamily: fontFamily}}>
      {lbToBr(props.message)}
    </div>
  );
}) as React.FC<Props>;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import SelectCard from '../cards/SelectCard';
import ForwardButton from '../buttons/ForwardButton';

import { LANGUAGES } from '../../i18n';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    cardGrid: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    cardContent: {
      flexGrow: 1,
    },
  }),
);

export default (() => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const classes = useStyles();
  const [lang, setLang] = useState('ja');

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    <>
      <main>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h3" variant="h3" align="center">
              OWNDAYSへようこそ
            </Typography>
            <Typography component="h4" variant="h4" align="center" paragraph>
              Welcome to OWNDAYS
            </Typography>
            <Grid item xs={12} style={{ textAlign: 'center', }}>
              <img src="/static/images/owndays.png" style={{ width: '20%', height: 'auto' }} alt="owndays" />
            </Grid>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          <Grid container spacing={4}>
            {LANGUAGES.map((language, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <SelectCard
                  fontSize="2.8vmin"
                  isSelected={language.lang === lang}
                  message={language.name}
                  onClick={() => { setLang(language.lang)}}/>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      <ForwardButton onClick={() => history.push('/purposeSelection')}/>
    </>
  );
}) as React.FC;
